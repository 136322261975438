import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRole } from '../models/UserRole';
import { Region } from '../models/Region';
import { HttpClientHelper } from '../models/HttpClientHelper';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { QueryResult } from '../models/QueryResult';
import { Department } from '../models/Department';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  departments: Department[] = [];
  roles: UserRole[] = [];

  constructor(public http: HttpClient, public router: Router, public notificationsService: NotificationsService) {
    http.get<UserRole[]>(`${HttpClientHelper.baseURL}/api/UserRoles`).subscribe(result => {
      this.roles = [];
      this.roles = result;
    }); //, error => console.error(error));
    http.get<Department[]>(`${HttpClientHelper.baseURL}/api/Departments`).subscribe(result => {
      this.departments = [];
      this.departments = result;
    }); //, error => console.error(error));
  }

  btnCancel() {
    this.router.navigate(['/Users']);
  }
  onSubmit(user) {
    let _user = user.value;
    if (_user.FirstName == "") {
      this.notificationsService.error("First Name Missing", "Please enter user's firstname to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    if (_user.LastName == "") {
      this.notificationsService.error("Last Name Missing", "Please enter user's Lastname to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    var reg = /^([\w-\.]+@(?!lsu.edu)([\w-]+\.)+[\w-]{2,4})?$/;
    if (reg.test(_user.Email) == false) {
      this.notificationsService.error("Invalid Email", "Please enter a valid email address to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }

    if (_user.Password == "") {
      this.notificationsService.error("Password Required", "Please enter user's Password to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    if (_user.Password != _user.ConfirmPassword) {
      this.notificationsService.error("Passwords Don't Match", "Please make sure Password Confirmation matches the Password.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }

    if (_user.RoleId < 1) {
      this.notificationsService.error("User Rights Missing", "Please select user rights to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    if (_user.RegionId < 1) {
      this.notificationsService.error("Region Missing", "Please select region to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    this.http.post<QueryResult>(`${HttpClientHelper.baseURL}/api/Account/Register`,
      user.value)
      .subscribe(
      response => {
        if (response.IsSuccess) {
          this.notificationsService.success('Create User', _user.FirstName + "'s account was created!", {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: false
          });
          this.router.navigate(['/Users']);
        }
        else {
          this.notificationsService.error("Create User",  response.ErrorMessages.toString(), {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: false
          });
        }
      },
        error => {
          console.log("Error", error);
        });
  }
  ngOnInit() {

  }

}
