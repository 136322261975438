import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserViewModel } from '../models/UserViewModel';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  votes: number = 10;
  currentUser: UserViewModel = null;
  constructor(public http: HttpClient) {
    http.get<UserViewModel>('/api/System/GetCurrentUser').subscribe(result => {
      if (result) {
        this.currentUser = result;
      }
    });//, error => console.error(error));
  }
  getVotes() {
    return this.votes;
  }
  setVotes(vote: number) {
    this.votes = vote;
  }
}
