import { Component } from '@angular/core';
import { UserViewModel } from './models/UserViewModel';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  currentUser: UserViewModel;
  constructor(public http: HttpClient) {
    debugger;
    //http.get<UserViewModel>('/api/System/GetCurrentUser').subscribe(result => {
    //  if (result) {
    //    this.currentUser = result;
    //    console.log(this.currentUser);
    //  }
    //});//, error => console.error(error));
  }
  ngOnInit() {
  }
}
