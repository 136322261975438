import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRole } from '../models/UserRole';
import { Region } from '../models/Region';
import { HttpClientHelper } from '../models/HttpClientHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { UserViewModel } from '../models/UserViewModel';
import { QueryResult } from '../models/QueryResult';
import { NotificationsService } from 'angular2-notifications';
import { User } from '../models/User';
import { Department } from '../models/Department';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  departments: Department[] = [];
  roles: UserRole[] = [];
  user: User;
  userId: String;
  constructor(public http: HttpClient, public router: Router, public route: ActivatedRoute, public notificationsService: NotificationsService) {
    http.get<UserRole[]>(`${HttpClientHelper.baseURL}/api/UserRoles`).subscribe(result => {
      this.roles = [];
      this.roles = result;
    //, error => console.error(error));
      http.get<Department[]>(`${HttpClientHelper.baseURL}/api/Departments`).subscribe(result => {
        debugger;
      this.departments = [];
      this.departments = result;
      this.route.params.subscribe(params => this.GetUser(params.id));
    }); //, error => console.error(error));
 });
  }

  btnCancel() {
    this.router.navigate(['/Users']);
  }


  btnUpdateStatus(user, isEnabled: Boolean) {
    user.IsEnabled = isEnabled;
    this.http.put<QueryResult>(`${HttpClientHelper.baseURL}/api/Users/${this.user.Id}`,
      user)
      .subscribe(
        response => {
          if (response.IsSuccess) {
            this.notificationsService.success("Update User", user.FirstName + "'s account was updated successfullly!", {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false
            });
            this.user.IsEnabled = isEnabled;
          }
          else {
            this.notificationsService.warn("Update User", user.FirstName + "'s account was not updated.\n" + response.ErrorMessages.toString(), {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false
            });
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }

  deleteUser(user) {
      
      this.http.delete<QueryResult>(`${HttpClientHelper.baseURL}/api/Users/${user.Id}`).subscribe(result => {
        debugger;
        if (result.IsSuccess) {
          //remove(user);
          this.notificationsService.warn('Delete User', user.FirstName + ' was deleted successfullly!', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: false
          });
          this.router.navigate(['/Users']);
        };
      });

    }

  onSubmit(user):void {
    let _user = user.value;
    var reg = /^([\w-\.]+@(?!lsu.edu)([\w-]+\.)+[\w-]{2,4})?$/;
    if (reg.test(_user.Email) == false) {
      this.notificationsService.error("Invalid Email", "Please enter a valid email address to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    if (_user.RoleId < 1) {
      this.notificationsService.error("User Rights Missing", "Please select user rights to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }
    if (_user.DepartmentId < 1) {
      this.notificationsService.error("Department Missing", "Please select Department to continue.", {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false
      });
      return;
    }


    this.http.put<QueryResult>(`${HttpClientHelper.baseURL}/api/Users/${this.userId}`,
      user.value)
      .subscribe(
        response => {
          if (response.IsSuccess) {
            this.notificationsService.success("Update User", _user.FirstName + "'s account was updated successfullly!", {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false
            });
            this.router.navigate(['/Users']);
          }
          else {
            this.notificationsService.warn("Update User", _user.FirstName + "'s account was not updated.\n" + response.ErrorMessages.toString(), {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false
            });
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  ngOnInit() {

  }

  GetUser(id: String): void {
    debugger;
    this.userId = id;
    this.http.get<User>(`${HttpClientHelper.baseURL}/api/Users/${id}`).subscribe(result => {
      debugger;
      this.user = result;
      console.log(this.user);
    });
  }
}
