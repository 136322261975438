import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserViewModel } from '../models/UserViewModel';
import { NotificationsService } from 'angular2-notifications';
import { QueryResult } from '../models/QueryResult';
import { Router } from '@angular/router';
import { UsersViewModel } from '../models/UsersViewModel';
import { User } from '../models/User';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent  {
  data: UsersViewModel;
  users: User[];
  baseUrl: string = "https://vlog.api.esims.co.za/";
  constructor(public http: HttpClient, public notificationsService: NotificationsService, public router: Router) {
    http.get<UsersViewModel>(this.baseUrl + 'api/Users?CurrentPage=10&PageSize=10').subscribe(result => {
      debugger;
      this.data = result;
      this.users = this.data.Users;
      console.log(this.users);
    }, error => console.error(error));
  }

  ngOnInit() {
  }

  deleteUser(user) {
    this.http.delete<QueryResult>(this.baseUrl + 'api/Users/' + user.Id).subscribe(result => {
      debugger;
      if (result.IsSuccess) {
        //remove(user);
        this.notificationsService.warn('Delete User', user.FirstName + ' was deleted successfullly!', {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: false
        });
        const index: number = this.users.indexOf(user);
        if (index > -1) {
          this.users.splice(index, 1);
        }
      }
      else {
        this.notificationsService.warn("Delete User", "Error deleting user account.\n" + result.ErrorMessages.toString(), {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: false
        });
      }
    });

  }
  viewUser(user) {
      this.router.navigate(['/EditUser/' + user.Id]);
  }
  btnAddNew() {
    this.router.navigate(['/AddUser']);
  }
}
