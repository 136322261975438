import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { WindowsUser } from '../models/WindowsUser';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  constructor() {
    debugger;
  }
  ngOnInit() {
  }

}
